<template>
  <el-drawer
    :title="!dataForm.id ? '新增套餐' : !disabled ? '修改套餐' : '查看套餐'"
    :visible.sync="visible"
    direction="rtl"
    append-to-body
    destroy-on-close
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :wrapperClosable="false"
    class="normalDrwer"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="套餐名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="输入套餐名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐编号" prop="sn">
          <el-input
            v-model="dataForm.sn"
            :disabled="disabled"
            placeholder="输入套餐名称"
          />
        </el-form-item>
        <el-form-item label="套餐分类" prop="categoryId">
          <el-select
            v-model="dataForm.categoryId"
            placeholder="套餐分类"
            :disabled="disabled"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否使用" prop="isUse">
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            style="line-height: 27px"
          >
            <i class="el-icon-question" />

            <div style="width: 200px" slot="content">
              禁用后将不能购买该套餐（现有订单不受影响）
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isUse"
            :disabled="disabled"
            placeholder="是否使用"
            >是否使用</el-checkbox
          >
        </el-form-item>
        <el-form-item label="可进柜" prop="isInFreezer">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将该商品不可进柜
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isInFreezer"
            :disabled="disabled"
            placeholder="可进柜"
            >可进柜</el-checkbox
          >
        </el-form-item>
        <el-form-item label="可堂食" prop="isCanteen">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将该商品不可堂食
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isCanteen"
            :disabled="disabled"
            placeholder="可堂食"
            >可堂食</el-checkbox
          >
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input
            v-model="dataForm.price"
            :disabled="disabled"
            placeholder="输入价格"
          />
        </el-form-item>
        <el-form-item label="限购数量" prop="limitedNum">
          <el-input
            v-model="dataForm.limitedNum"
            :disabled="disabled"
            placeholder="输入限购数量"
          />
        </el-form-item>
        <el-form-item
          label="中转架规格"
          prop="shelfStyleIds"
          v-if="dataForm.isInFreezer"
        >
          <el-select
            v-model="dataForm.shelfStyleIds"
            filterable
            :disabled="disabled"
            multiple
          >
            <el-option
              v-for="(item, index) in shelfStyleList"
              :key="index"
              :label="item.shelfStyle"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="套餐描述" prop="descriptionList" class="fullList">
          <el-button
            type="primary"
            size="mini"
            style="margin: 0 0 10px"
            @click="addDescription"
            class="el-icon-add-location"
            v-if="!disabled"
            >添加</el-button
          >
          <div class="form-table">
            <div class="table-header">
              <div class="table-header-item">属性</div>
              <div class="table-header-item">值</div>
              <div class="table-header-item">操作</div>
            </div>
            <div class="table-body">
              <div
                v-for="(descriptions, index) in dataForm.descriptionList"
                :key="index"
                class="table-body-wrap"
              >
                <div class="table-body-item">
                  <el-input v-model="descriptions.key"></el-input>
                </div>
                <div class="table-body-item">
                  <el-input v-model="descriptions.value"></el-input>
                </div>
                <div class="table-body-item">
                  <i
                    @click="handleDeleteItem(descriptions, index)"
                    class="el-icon-delete"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <!--                <el-form-item label="销量" prop="salesVolume">-->
        <!--                    <el-input-->
        <!--                        v-model="dataForm.salesVolume"-->
        <!--                        :disabled="disabled"-->
        <!--                        placeholder="输入销量"-->
        <!--                    ></el-input>-->
        <!--                </el-form-item>-->
        <el-form-item label="套餐图片" prop="imageUrl" class="fullList">
          <image-cropper-modal
            :visible="cropperVisible"
            :url="file"
            :auto-crop-width="autoCropWidth"
            :auto-crop-height="autoCropHeight"
            @cancel="cropperVisible = false"
            @confirm="onConfirm"
          />
          <el-upload
            v-model="dataForm.imageUrl"
            class="avatar-uploader"
            action="#"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="changeUpload"
          >
            <img
              v-if="dataForm.imageUrl"
              :src="dataForm.imageUrl"
              class="avatar"
              alt=""
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="商品图片链接" prop="imageUrl" class="fullList">
          <el-input v-model="dataForm.imageUrl"></el-input>
        </el-form-item>
        <div class="insideList">
          <el-button
            type="primary"
            @click="addPackageDetail()"
            class="el-icon-add-location"
            v-if="!disabled"
            size="mini"
            >添加套餐搭配</el-button
          >
        </div>

        <div class="form-table">
          <div class="headLine">自动搭配</div>
          <div class="btnLine">
            <el-switch
              v-model="goodsAutoChoose"
              active-text="自动搭配"
              inactive-text="手动选择"
              @change="changeAutoChoose()"
              :active-value="1"
              :inactive-value="0"
            />
          </div>
          <div class="btnLine">
            <el-radio-group
              v-model="autoCollocation"
              @input="changeAuto()"
              v-if="goodsAutoChoose === 1"
            >
              <el-radio
                v-for="(item, index) in dataForm.packageDetailList"
                :key="index"
                :label="item.name"
                >{{ item.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <el-table-draggable>
            <el-table
              :data="dataForm.packageDetailList"
              row-key="id"
              stripe
              class="insideTable"
            >
              <el-table-column
                label="是否搭配"
                prop="isCollocation"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isCollocation"
                    :disabled="disabled"
                    @change="changePackageDetail(scope.row)"
                    placeholder="是否搭配"
                    >是否搭配</el-checkbox
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="是否必选"
                prop="isRequired"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.isRequired"
                    :disabled="disabled || !scope.row.isCollocation"
                    placeholder="是否必选"
                    >是否必选</el-checkbox
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="categoryId"
                label="分类"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :key="'categoryId ' + scope.$index"
                    :prop="`packageDetailList.${scope.$index}.categoryId`"
                    :rules="dataRule.packageCategoryId"
                    class="labelFullList"
                  >
                    <el-select
                      v-model="scope.row.categoryId"
                      @change="changePackageDetail(scope.row)"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="(item, index) in categoryList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="属性及数量"
                header-align="center"
                align="center"
              >
                <el-table-column
                  label="属性"
                  prop="isCollocation"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="!scope.row.isCollocation" type="success"
                      >必选数量</el-tag
                    >
                    <el-tag v-if="scope.row.isCollocation" type="warning"
                      >最大可选数量</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="limitedNum"
                  label="数量"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :key="'limitedNum ' + scope.$index"
                      :prop="`packageDetailList.${scope.$index}.limitedNum`"
                      :rules="dataRule.packageLimitedNum"
                      class="labelFullList"
                    >
                      <el-input-number
                        :min="1"
                        :max="10"
                        size="mini"
                        v-model="scope.row.limitedNum"
                        @change="changePackageDetail(scope.row)"
                      >
                      </el-input-number>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                label="名称"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :key="'name ' + scope.$index"
                    :prop="`packageDetailList.${scope.$index}.name`"
                    :rules="dataRule.packageName"
                    class="labelFullList"
                  >
                    <el-input v-model="scope.row.name" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <i
                    @click="handleDeletePackageItem(scope.row, scope.$index)"
                    class="el-icon-delete"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-table-draggable>
        </div>
      </div>
    </el-form>
    <div class="btnGroup">
      <el-button @click="btnClose">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import ElTableDraggable from 'el-table-draggable';

export default {
  data() {
    return {
      disabled: false,
      autoCollocation: '',
      visible: false,
      goodsAutoChoose: 0, // 1为自动搭配  0为非自动搭配，手动选择
      dataForm: {
        id: '',
        name: '',
        sn: '',
        categoryId: '',
        descriptionList: [],
        imageUrl: '',
        isUse: true,
        price: '',
        limitedNum: 1,
        // salesVolume: '',
        shelfStyleId: '',
        shelfStyleIds: [],
        orgId: '',
        isInFreezer: true,
        isCanteen: true,
        packageDetailList: [
          {
            limitedNum: 1,
            isCollocation: false,
            isRequired: true,
            isAutoCollocation: false,
            name: '',
            categoryId: '',
          },
        ],
      },

      autoCropWidth: '400',
      autoCropHeight: '400',
      file: '',
      fileName: '',
      cropperVisible: false,
      categoryList: [],
      shelfStyleList: [],
      dataRule: {
        name: [
          { required: true, message: '套餐名称不能为空', trigger: 'blur' },
        ],
        sn: [{ required: true, message: '套餐编号不能为空', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '套餐分类不能为空', trigger: 'blur' },
        ],
        price: [{ required: true, message: '价格不能为空', trigger: 'blur' }],
        packageCategoryId: [
          { required: true, message: '套餐分类不能为空', trigger: 'blur' },
        ],
        packageName: [
          { required: true, message: '套餐搭配名不能为空', trigger: 'blur' },
        ],
        packageLimitedNum: [
          { required: true, message: '套餐数量不能为空', trigger: 'blur' },
        ],
        shelfStyleIds: [
          { required: true, message: '中转架规格不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  components: {
    ElTableDraggable,
  },
  created() {
    this.getShelfStyle();
  },
  methods: {
    init(id, disabled, orgId) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.orgId = orgId;
      this.visible = true;
      this.goodsAutoChoose = 0;
      this.dataForm.packageDetailList = [];
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/package/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.package;
              this.goodsAutoChoose = data.package.goodsAutoChoose;
              this.autoCollocation = data.package.goodsAutoName;
            }
          });
        }
      });
      this.getCategoryList();
    },
    beforeClose(done) {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
      done();
    },
    btnClose() {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
      this.visible = false;
    },
    changeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1);
      const suffix = img === 'jpg' || img === 'png' || img === 'jpeg';
      if (!suffix) {
        this.$message.error('只能上传图片');
        return false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.file =
          typeof reader.result === 'object'
            ? window.URL.createObjectURL(new Blob([reader.result]))
            : reader.result;
      };
      this.cropperVisible = true;
      reader.readAsArrayBuffer(file.raw);
      this.fileName = file.name;
    },
    async onConfirm(blob) {
      const form = new FormData();
      form.append('file', blob);
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data: form,
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.imageUrl = data.data.url;
          this.cropperVisible = false;
        }
      });
    },
    uploadImg(data) {
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm = data.package;
        }
      });
    },
    getCategoryList() {
      this.$http({
        url: `/cc/category/all`,
        params: {
          orgId: this.dataForm.orgId,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.categoryList = data.data.items;
        }
      });
    },
    getShelfStyle() {
      this.$http({
        url: '/cc/shelfstyle/shelfStyle',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.shelfStyleList = data.shelfStyleList;
        } else {
          this.shelfStyleList = [];
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.packageDetailList.map((item, index) => {
        item.orderNum = index;
      });
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/package/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$refs['dataForm'].resetFields();
              this.$emit('success');
            }
          });
        }
      });
    },
    //套餐搭配名字跟随选项变化
    changePackageDetail(packageDetail) {
      let categoryName = this.categoryList.filter(
        (item) => item.id === packageDetail.categoryId,
      )[0].name;
      let limitedNum = packageDetail.limitedNum;
      let isCollocation = packageDetail.isCollocation;
      if (!isCollocation && limitedNum > 1) {
        packageDetail.name = categoryName + '(选' + limitedNum + ')';
      } else {
        packageDetail.name = categoryName;
      }
    },
    changeAuto() {
      this.dataForm.packageDetailList.forEach(
        (item) => (item.isAutoCollocation = false),
      );
      this.dataForm.packageDetailList.filter(
        (item) => item.name === this.autoCollocation,
      )[0].isAutoCollocation = true;
    },
    changeAutoChoose() {
      if (this.goodsAutoChoose == 0) {
        this.dataForm.packageDetailList.forEach(
          (e) => (e.isAutoCollocation = false),
        );
      }
    },

    handleDeleteItem(item, index) {
      this.dataForm.descriptionList.splice(index, 1);
    },
    handleDeletePackageItem(item, index) {
      this.dataForm.packageDetailList.splice(index, 1);
    },
    addDescription() {
      this.dataForm.descriptionList.push({
        key: '',
        value: '',
      });
    },
    addPackageDetail() {
      this.dataForm.packageDetailList.push({
        categoryId: '',
        isAutoCollocation: false,
        isCollocation: false,
        isRequired: true,
        name: '',
        limitedNum: 1,
      });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  border-radius: 0 !important;
  display: block;
}
</style>
